import { FluidObject } from 'gatsby-image';

interface IBodyIncomeParam {
  structure: string;
}

export interface IStructureToPropsMapResult {
  [dataKey: string]: {
    properties: any;
  };
}

export const getSvgOrFluid = (img: AppImages.IResponsiveImageData): string | FluidObject =>
  img?.gatsbyImage === null ? img?.fallbackUrl : img?.gatsbyImage?.childImageSharp?.fluid || '';

const getStructureToPropsMap = (body: IBodyIncomeParam[]): IStructureToPropsMapResult => {
  const params = {};

  body.forEach((item) => {
    params[item.structure] = item;
  });

  return params;
};

export default {
  getStructureToPropsMap,
};
