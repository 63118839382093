import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import IconCustom from 'components/common/IconCustom';

import { IIllustration } from '../model';
import './SymptomIllustration.scss';

const SymptomIllustration: FC<IIllustration> = ({
  title,
  imageAlt,
  image,
  path,
  description,
  isTitleFirst,
}) => {
  const classes = classNames('nf-symptom-illustration', {
    'has-description': description,
    'text-center': isTitleFirst,
  });

  const symptomContent = (
    <div className={classes} data-test="SymptomIllustration">
      <div className="nf-symptom-illustration__illustration_holder">
        {!isTitleFirst && image ? (
          <UniversalImage
            imageAlt={imageAlt}
            img={image}
            imageStyle={{ width: '73px', height: '73px' }}
          />
        ) : null}
        {description ? (
          <span className="nf-symptom-illustration__item-text">{description}</span>
        ) : null}
        {title ? <span className="nf-symptom-illustration__title">{title}</span> : null}
        {isTitleFirst && image ? (
          <UniversalImage
            imageAlt={imageAlt}
            img={image}
            imageStyle={{ width: '73px', height: '73px' }}
          />
        ) : null}
        {!isTitleFirst ? <IconCustom icon="arrow_forward_ios" /> : null}
      </div>
    </div>
  );

  return path ? (
    <Link to={path} className="nf-symptom-illustration__holder-link">
      {symptomContent}
    </Link>
  ) : (
    symptomContent
  );
};
export default SymptomIllustration;
