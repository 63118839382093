import React, { FC, ReactElement } from 'react';

import { IBodyStructureElement, IPropsNFBodyRenderer } from './model';

const NFBodyRenderer: FC<IPropsNFBodyRenderer> = (props): ReactElement => {
  const { bodyStructure, expectedStructures, parsedData, additionalBodyProps = {} } = props;

  return (
    <>
      {bodyStructure.map((item: IBodyStructureElement) => {
        const structureData = expectedStructures[item.structure];
        if (!structureData) {
          return null;
        }
        const { Component, dataKey } = structureData;
        const componentProps = parsedData[dataKey] ? { ...parsedData[dataKey] } : {};

        return <Component key={item.structure} {...componentProps} {...additionalBodyProps} />;
      })}
    </>
  );
};

export default NFBodyRenderer;
