import { IResponsiveObj } from 'components/ControlledCarousel/model';

const responsiveObj: IResponsiveObj = {
  desktop: {
    breakpoint: { max: 6000, min: 992 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 991, min: 640 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    partialVisibilityGutter: 50,
  },
};

export default responsiveObj;
